import {action, makeAutoObservable, toJS} from "mobx";
import MapEntity from "../models/MapEntity";
import SchoolEntity from "../models/SchoolEntity";
import {APIHelper} from "../utlis/APIHelper";
import {ServerEntity} from "../models/ServerEntity";
import localforage from "localforage";
import ItemEntity from "../models/ItemEntity";
import SkillEntity from "../models/SkillEntity";
import {ActivePointRewardEntity} from "../models/ActivePointRewardEntity";
import {TerritoryArea} from "../models/TerritoryArea";
import {ProductEntity} from "../models/ProductEntity";
import {ArchiveEntity} from "../models/ArchiveEntity";
import PlayerArchiveEntity from "../models/PlayerArchiveEntity";


// 新增类型声明
declare global {
  interface Window {
    audioContext: AudioContext;
  }
}

export interface MapGroup {
    name: string;
    minLevelRequire: number;
    maxLevelRequire: number;
    map: MapEntity;
}
export interface VipConfig {
    level:number;
    requirePoints:number;
    battleClaimScale: number;
    territoryClaimScale: number;
    idleClaimScale: number;
    dungeonClaimScale: number;
    songjinClaimScale: number;
    menpaiClaimScale: number;
    dailyTaskClaimScale:number;
};

export class Config {
    // 新增音频相关属性
  private audio: HTMLAudioElement | null = null;
  private songs = [
    process.env.PUBLIC_URL + '/images/mp3/yzz.mp3',
    process.env.PUBLIC_URL + '/images/mp3/lqc.mp3',
    process.env.PUBLIC_URL + '/images/mp3/cdf.mp3',
	process.env.PUBLIC_URL + '/images/mp3/yzf.mp3',
	process.env.PUBLIC_URL + '/images/mp3/bht.mp3',
	process.env.PUBLIC_URL + '/images/mp3/sjzc.mp3'
  ];
  private currentSongIndex = 0;
  isMusicPlaying = false;
  _audioInitialized = false;

  constructor() {
    makeAutoObservable(this);
    this.initializeAudio();
    this.loadMusicState();
    }
	
	
  // 新增音频初始化方法
    private initializeAudio() {
    if (typeof window !== "undefined" && !this._audioInitialized) {
      this._audioInitialized = true;
      this.currentSongIndex = Math.floor(Math.random() * this.songs.length);
      this.createAudioElement();
      
      // 处理Safari自动播放限制
      window.addEventListener('click', this.handleFirstPlay, { once: true });
    }
  }

  private createAudioElement() {
    this.audio = new Audio(this.songs[this.currentSongIndex]);
    this.audio.loop = false; // 关闭单曲循环
    this.audio.volume = 0.5;
    
    // 添加播放结束监听
    this.audio.addEventListener('ended', this.playNextRandomSong);
  }

  private playNextRandomSong = () => {
    if (!this.isMusicPlaying) return;
    
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * this.songs.length);
    } while (newIndex === this.currentSongIndex); // 确保不重复同一首
    
    this.currentSongIndex = newIndex;
    this.createAudioElement();
    this.safePlay();
  }
  
  // 修改保存和加载状态逻辑
  public saveMusicState() {
    localStorage.setItem("musicState", JSON.stringify({
      isPlaying: this.isMusicPlaying,
      volume: this.audio?.volume || 0.5
    }));
  }

  public loadMusicState() {
    const savedState = localStorage.getItem("musicState");
    if (savedState) {
      const { isPlaying, volume } = JSON.parse(savedState);
      this.isMusicPlaying = isPlaying;
      if (this.audio) {
        this.audio.volume = volume;
      }
    }
  }
  
  // 安全播放方法
  private safePlay() {
    this.audio?.play().catch(error => {
      console.log("自动播放被阻止:", error);
      this.isMusicPlaying = false;
    });
  }

  // 首次交互处理
  handleFirstPlay = () => {
    if (this.isMusicPlaying && this.audio?.paused) {
      this.safePlay();
    }
  };
  
  // 修改toggle方法
  @action
  public toggleMusic() {
    this.isMusicPlaying = !this.isMusicPlaying;
    
    if (this.isMusicPlaying) {
      if (this.audio?.paused) {
        this.safePlay();
      }
    } else {
      this.audio?.pause();
    }
    
    this.saveMusicState();
  }

    public async Init() {
        var configs = await APIHelper.GetGameConfigs();
        var maps = configs.gameMaps as Array<MapEntity>;
        var skills = configs.skills as Array<SkillEntity>;
        var servers = await APIHelper.ServerList();
        this.currentServer = servers[0];
        this.servers = servers;
        maps.forEach((map) => {
            if (map.mapType === 4) {
                this.dungeons.push({
                    name: map.regionName,
                    map: map,
                    maxLevelRequire: map.maxRequireLevel,
                    minLevelRequire: map.minRequireLevel
                });
            } else {
                this.maps.push({
                    name: map.regionName,
                    map: map,
                    maxLevelRequire: map.maxRequireLevel,
                    minLevelRequire: map.minRequireLevel
                });
                this.maps.sort((a, b) => a.minLevelRequire - b.minLevelRequire)
            }

        });
        skills.map((item) => {
            item.activeEffects.sort((i) => i.attribute === "damage" ? -1 : 1)
            this.gameSkillMap.set(item.id, item);
        })
        this.school = configs.schools;
        this.rewards = configs.rewards;
        this.archives = configs.archives;
        this.vipConfigs = configs.vip;
        this.currentServer = await localforage.getItem("LastEnterServer");
        this.itemTemplates = await APIHelper.ItemTemplateList();
        this.itemTemplates.forEach((i) => this.itemTemplateMap.set(i.id, i));
        this.products = (await APIHelper.ListProduct()).data;

    }

    vipConfigs:Array<VipConfig> = [];
    maps: Array<MapGroup> = [];
    dungeons: Array<MapGroup> = [];
    archives: Array<ArchiveEntity> = [];
    servers: Array<ServerEntity> = []
    school: Array<SchoolEntity> = []
    products: Array<ProductEntity> = [];
    currentServer: ServerEntity;
    itemTemplates: Array<ItemEntity> = []
    rewards: Array<ActivePointRewardEntity> = []
    itemTemplateMap: Map<string, ItemEntity> = new Map<string, ItemEntity>();
    gameSkillMap: Map<string, SkillEntity> = new Map<string, SkillEntity>();
    territories: Array<TerritoryArea> = new Array<TerritoryArea>();

    @action
    async updateServer(server: ServerEntity) {
        this.currentServer = server;
        var obj = null;
        if (server) {
            await localforage.setItem("LastEnterServer", toJS(server));
        } else {
            await localforage.removeItem("LastEnterServer");

        }
    }

    @action refreshTerritory() {
        return APIHelper.GetTerritoryList().then((res) => {
            this.territories = res.data;
        });
    }
}
