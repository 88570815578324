import {Component, Fragment} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import PlayerSkillEntity from "../../models/PlayerSkillEntity";
import {ArchiveEntity} from "../../models/ArchiveEntity";
import PlayerArchiveEntity from "../../models/PlayerArchiveEntity";

interface ArchiveItemProps {
    data: ArchiveEntity;
    playerData?: PlayerArchiveEntity;
    onClick: (data: ArchiveEntity) => void;
    onEquipClick: (data: PlayerArchiveEntity) => void;
    onUnloadClick: (data: PlayerArchiveEntity) => void;

}

export default class ArchiveItem extends Component<ArchiveItemProps, any> {

    render() {
        let {data,playerData} = this.props;
        var color = data.level <= 1 ? "#00EEFF" : (data.level <= 2 ? "#C800FF" : "#F6C509")
        return <div style={{
            width: "7.26rem",
            height: "1.12rem",
            margin: "0.1rem 0 0",
            display: "flex",
            background: "#3B7082",
            padding: "0.06rem 0.2rem 0.06rem 0.06rem",
            justifyContent: "space-between",
            boxSizing: "border-box"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "4.5rem",
                textAlign: "left",
                margin: "0 0.12rem",
                boxSizing: "border-box",
                padding: "0 0 0.05rem 0",
                justifyContent: "space-between"
            }}>
                <div
                    style={{
                        fontSize: "0.28rem",
                        color: color
                    }}>{data.name}{data.level === 0 ? `(未解锁)` : (" Lv." + data.level)}</div>
                <div
                    style={{fontSize: "0.24rem"}}>要求等级:Lv.{data.requireLevel}</div>
            </div>
            <div style={{flex: "1"}}></div>
            <Fragment>
                {
                    !this.props.playerData && <Button onClick={() => this.props.onClick(data)} style={{
                        width: "0.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto"
                    }}>兑换</Button>
                }

                {
                    this.props.playerData && this.props.playerData.equip == 0 && <Button onClick={() => this.props.onEquipClick(playerData)} style={{
                        width: "0.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto"
                    }}>装备</Button>
                }

                {
                    this.props.playerData && this.props.playerData.equip == 1 && <Button onClick={() => this.props.onUnloadClick(playerData)} style={{
                        width: "0.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto"
                    }}>卸下</Button>
                }
            </Fragment>

        </div>;
    }
}
