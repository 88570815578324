import {Component} from "react";
import {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {inject, observer} from "mobx-react";
import {Button, Input, List, Space} from "antd-mobile";
import "./index.less"
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";

interface IChatProps extends IRouterProps, IStore {

}

interface IChatState {
    inputMessage: string;
}

@inject("player", "config", "message")
@observer
export default class Chat extends Component<IChatProps, IChatState> {
    private messageList: HTMLDivElement;

    constructor(props: IChatProps) {
        super(props);
        this.state = {
            inputMessage: ""
        }
    }

    render() {
        const {messages} = this.props.message;
        return <div style={{display: "flex", flexDirection: "column", height: "100%", position: "relative"}}>
            <div ref={(ref) => this.messageList = ref} className="message-list">
                <List>
                    {
                        messages.map((msg) => {
                            return <List.Item key={msg.id} extra={<div style={{fontSize:"0.28rem"}}>{msg.time.toLocaleTimeString()}</div>} prefix={<div>
                                {msg.senderName}:
                            </div>}>{msg.content}</List.Item>
                        })

                    }
                    <List.Item key={"-1"} prefix={<div>
                    </div>}></List.Item>
                </List>
            </div>
            <div style={{
                width: '100%',
                display: "flex",
                border: "solid 1px #072335",
                fontSize: "0.48rem",
                lineHeight: "0.48rem",
                height: "0.8rem"
            }}>
                <Input value={this.state.inputMessage} onChange={(str) => this.setState({inputMessage: str})}
                       placeholder="请输入"/>
                <Button onClick={this.sendMessage} size={"small"} style={{fontSize: "0.24rem"}}
                        type="submit">发送</Button>
            </div>
        </div>;
    }

    sendMessage = () => {
        if (this.state.inputMessage.length <= 0) {
            ModalUtils.ShowSingleConfirm("提示", "消息不能为空");
            return;
        }
        APIHelper.SendMessage(this.state.inputMessage).then(() => {
            setTimeout(() => {
                this.messageList.scrollTo({top: this.messageList.scrollHeight - this.messageList.clientHeight})
            })
        });
        this.setState({inputMessage: ""})
    }
}
